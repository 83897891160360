body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    background-color: #f8f9fa;
}

h2 {
    font-weight: 300;
    color: #343a40;
}

.card {
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-control {
    border-radius: 5px;
}

.btn-primary {
    background-color: #007bff;
    border: none;
    transition: background-color 0.3s;
}

.btn-primary:hover {
    background-color: #0056b3;
}

.card-title {
    font-weight: 500;
    font-size: 1.2em;
}

.card-text {
    font-size: 1em;
}

.alert {
    margin-top: 10px;
}
