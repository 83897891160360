body {
    background-color: #f4f4f9;
    color: #333;
    font-family: 'Roboto', sans-serif;
}

.header {
    text-align: center;
    background-color: #0056b3;
    color: #ffffff;
    padding: 20px;
    margin-bottom: 20px;
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: uppercase;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.stats-card {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    margin: 10px 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
}

.stats-card:hover {
    transform: translateY(-3px) scale(1.02);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    background-color: #f0f8ff;
}

.card-title {
    font-size: 1.1rem;
    color: #0056b3;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-bottom: 10px;
}

.card-text {
    font-size: 2rem;
    font-weight: bold;
    color: #007bff;
    transition: color 0.3s ease, transform 0.3s ease;
}

.card-text.change {
    color: #ff4500;
    transform: scale(3.0);
}

@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
}

.stats-card:hover .card-text {
    animation: pulse 1s infinite;
}

.container {
    padding: 20px;
}

.row {
    margin-bottom: 20px;
}

footer {
    text-align: center;
    color: #666;
    margin-top: 20px;
    font-size: 0.9rem;
    opacity: 0.8;
}
