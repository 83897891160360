.card {
    margin-top: 20px;
    border: none;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.table-striped > tbody > tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
}

.table thead th {
    background-color: #007bff;
    color: #fff;
    border-bottom: 2px solid #dee2e6;
}

.table td, .table th {
    vertical-align: middle;
}
