.recent-user-item {
    margin-bottom: 0.5rem;
}

.ToastContainer .Toast {
    background-color: #343a40;
    color: #ffffff;
}

.ToastContainer .Toast .Toast-Header {
    background-color: #495057;
    color: #ffffff;
}

.ToastContainer .Toast .Toast-Body {
    background-color: #343a40;
    color: #ffffff;
}
